
import { GameList } from "../src/list/GameList";
import { PageList } from "../src/list/PageList";
import { Assets } from "../src/list/Assets";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import dynamic from 'next/dynamic';

const Headcom = dynamic(() => import('../src/components/Headcom'), {  });
const CatBtn = dynamic(() => import('../src/components/CatBtn'), {  });
const HomeDis = dynamic(() => import('../src/Description/HomeDis'), {  });
const FooterDis = dynamic(() => import('../src/Description/FooterDis'), {  });
const CookieCom = dynamic(() => import('../src/components/CookieCom'), {  });
const FooterCom = dynamic(() => import('../src/components/FooterCom'), {  });
const Game_Components = dynamic(() => import('../src/components/Game_Components'), {  });
const BlackScreen = dynamic(() => import('../src/components/BlackScreen'), {  });
const Snow_fx = dynamic(() => import('../src/fx/Snow_fx'), {  });

export default function HomePage() {

  const path = useRouter();
  const [mobile, setMobile] = useState(false);

  
  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setMobile(isMobile);
  }, []);
  

// *************************************************************

const first50Games = GameList.slice(0, 50);


const getGamesByTag = (tag) => {
  try {
    if (!GameList || !Array.isArray(GameList)) {
      console.error('GameList is undefined or not an array');
      return [];
    }

    const filteredGames = GameList.filter(game => game.tag.includes(tag));

    // while (filteredGames.length < 10) {
    //   filteredGames.push(...filteredGames.slice(0, 10 - filteredGames.length));
    // }

    return filteredGames.slice(0, 30);
    // return filteredGames;
  } catch (error) {
    console.error('Error filtering games:', error);
    return [];
  }
};



  return (
    <>
      <div className="app_cont_game">
        <Headcom
          title={PageList[0].title}
          s_dis={PageList[0].s_dis}
          path={PageList[0].path[1] + PageList[0].path[0]}
          thumb={PageList[0].thumb}
          logo_16={Assets[0].logo_16}
          // logo_32={Assets[0].logo_32}
          logo_192={Assets[0].logo_192}
          logo_270={Assets[0].logo_270}
          logo_512={Assets[0].logo_512}
        />
        <div>
        
          <BlackScreen />
         
          <div className="game-root">
            <div className="container">
            <Game_Components/>
          <CatBtn />
          <HomeDis />
          <FooterDis />
          <CookieCom />
            </div>
          </div>
          
        </div>
      </div>
      <FooterCom />
      <Snow_fx />
    </>
  );
}